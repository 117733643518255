import './index.scss'
import '../../assets/scripts/jquery.scrolly.js'
import Swiper from 'swiper/swiper-bundle.min.js'
$("header .nav a").eq(0).addClass("active")
var swiper = new Swiper(".index-banner .mySwiper", {
    observer:true,//修改swiper自己或子元素时，自动初始化swiper
    observeParents:true,//修改swiper的父元素时，自动初始化swiper
    loop:true,
    speed:2000,
    autoplay: {
    delay: 3000,
    },
    navigation: {
        nextEl: ".index-banner .swiper-button-next",
        prevEl: ".index-banner .swiper-button-prev",
    },

      on: {
        init: function (swiper) {
          let total = this.slides.length - 2;
          if (total < 10) {
            $('.total').text("0" + total);
          } else {
            $('.total').text(total);
          }
          this.emit('transitionEnd');
        },
        transitionEnd: function () {
          var index = this.realIndex + 1;
          if (index < 10) {
            $(".index-banner .active").text("0" + index);
          } else {
            $(".index-banner .active").text(index);
          }
        }
      }
});

$(document).ready(function(){
  $('.parallax').scrolly({bgParallax: true});
});

// 产品轮播
var certifySwiper = new Swiper('#certify .swiper-container', {
  watchSlidesProgress: true,
	slidesPerView: 'auto',
	centeredSlides: true,
	loop: true,
	loopedSlides: 11,
	navigation: {
		nextEl: '#certify .swiper-button-next',
		prevEl: '#certify .swiper-button-prev',
	},
	on: {
		progress: function(progress) {
			for (var i = 0; i < this.slides.length; i++) {
				var slide = this.slides.eq(i);
				var slideProgress = this.slides[i].progress;
				var modify = 1;
				if (Math.abs(slideProgress) > 1) {
					modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
				}
				var translate = slideProgress * modify * 150 + 'px';
				var scale = 1 - Math.abs(slideProgress) / 8;
				var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
        // var opacity =1 - Math.abs(Math.round(slideProgress)) / 10;
				slide.transform('translateX(' + translate + ') scale(' + scale + ')');
				slide.css('zIndex', zIndex);
				slide.css('opacity', 1);
				if (Math.abs(slideProgress) > 11) {
					slide.css('opacity', 1);
				}
			}
		},
		setTransition: function(swiper, transition) {
			for (var i = 0; i < this.slides.length; i++) {
				var slide = this.slides.eq(i)
				slide.transition(transition);
			}

		}
	}

})

// 加入我们
var swiper = new Swiper ('#case4', {
  loop : true,       //允许从第一张到最后一张，或者从最后一张到第一张  循环属性
  slidesPerView :4,   // 设置显示三张
  //centeredSlides : true,     //使当前图片居中显示
  freeMode:true,        // 使幻灯片滑动时不止滑动一格，且不会自动贴合 
  autoplay: true,//可选选项，自动滑动
  speed:20000,//设置过度时间
  grabCursor: true,//鼠标样式根据浏览器不同而定 
  autoplay : {
    delay:1,
    disableOnInteraction: false,
  }, 
  breakpoints: {
    320: { //当屏幕宽度大于等于320
        slidesPerView: 2,
    },
    768: { //当屏幕宽度大于等于320
      slidesPerView: 3,
    },
    1280: { //当屏幕宽度大于等于1280
        slidesPerView: 4,
    }
}                
   
});

// 视频
$(".left .play").click(function () {
  var video = document.getElementById('myVideo');
  if (video.paused) { //如果已暂停则播放
      $('.left .play').hide();
      video.play(); //播放控制
  } else { // 已播放点击则暂停
      $('.left .play').show();
      video.pause(); //暂停控制
  }
})
$(".right .play").click(function () {
  var video = $(this).parent().parent().find("video")[0]
  if (video.paused) { //如果已暂停则播放
      $(this).hide();
      video.play(); //播放控制
  } else { // 已播放点击则暂停
      $(this).hide();
      video.pause(); //暂停控制
  }
})

if ( window.innerWidth>1768) {
  // 新闻
var t = $(window).scrollTop();
$(window).scroll(function () {
  // 可是窗口滚动条发生滚动的时候 垂直方向滚动到多少距离的时候 让头部导航条设置上一个固定定位的样式
  var scroll = $(window).scrollTop();
  var alpha1 = Math.min(480 - 0.4 * $(".section5 li").eq(0).offset().top / 7);
  var alpha1b = Math.min(460 - 0.3 * $(".section5 li").eq(0).offset().top / 7.5);
  var alpha1c = Math.min(460 - 0.3 * $(".section5 li").eq(0).offset().top / 8);
  var alpha1d = Math.min(460 - 0.3 * $(".section5 li").eq(0).offset().top / 8.5);
  if(scroll > t) {
    
  if ($(window).scrollTop()>=($(".section5 li").eq(0).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(1).offset().top - 200)) {
    $(".section5 li").eq(0).css({
      "transform":"rotateX("+ t / 200+"deg)" + "scale("+ 5000 / t+")",
      'top': t / 4500 + 'px',
      'background':'rgba(' + alpha1 + ',' + alpha1 + ',' + alpha1 + ')'
    })
  }
  else if($(window).scrollTop()>=($(".section5 li").eq(1).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(2).offset().top - 260)) {
    $(".section5 li").eq(1).css({
      "transform":"rotateX("+ t / 300 +"deg)" + "scale("+ 5500 / t+")",
      'background':'rgba(' + alpha1 + ',' + alpha1 + ',' + alpha1 + ')'
    });
  }
  else if($(window).scrollTop()>=($(".section5 li").eq(2).offset().top - 260) && $(window).scrollTop()<($("footer").offset().top - 700)) {
    $(".section5 li").eq(2).css({
      "transform":"rotateX("+ t / 200 +"deg)" + "scale("+  5650 / t+")",
    });
  }
    
  } else {
    var alpha1 = Math.min(480 - 0.4 * $(".section5 li").eq(0).offset().top / 7);
  if ($(window).scrollTop()>=($(".section5 li").eq(0).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(1).offset().top - 200)) {
    $(".section5 li").eq(0).css({
      "transform":"rotateX("  + t / t +"deg)" + "scale("+ 5000 / t+")",
      'top': t / 4500 + 'px',
      'background':'rgba(' + alpha1b + ',' + alpha1b + ',' + alpha1b + ')'
    })
  }
  else if($(window).scrollTop()>=($(".section5 li").eq(1).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(2).offset().top - 260)) {
    $(".section5 li").eq(1).css({
      "transform":"rotateX("+ t / t +"deg)" + "scale("+ 5500 / t+")",
      'background':'rgba(' + alpha1c + ',' + alpha1c + ',' + alpha1c + ')'
    });
  }
  else if($(window).scrollTop()>=($(".section5 li").eq(2).offset().top - 260) && $(window).scrollTop()<($("footer").offset().top - 700)) {
    $(".section5 li").eq(2).css({
      "transform":"rotateX("+ t / t+"deg)" + "scale("+  5650 / t+")",
    });
  }
  }
  t = scroll;
})
}else if( window.innerWidth>1366 && window.innerWidth<1768) {
  var t = $(window).scrollTop();
  $(window).scroll(function () {
    // 可是窗口滚动条发生滚动的时候 垂直方向滚动到多少距离的时候 让头部导航条设置上一个固定定位的样式
    var scroll = $(window).scrollTop();
    var alpha1 = Math.min(480 - 0.4 * $(".section5 li").eq(0).offset().top / 7);
    var alpha1b = Math.min(460 - 0.3 * $(".section5 li").eq(0).offset().top / 7.5);
    var alpha1c = Math.min(460 - 0.3 * $(".section5 li").eq(0).offset().top / 8);
    var alpha1d = Math.min(460 - 0.3 * $(".section5 li").eq(0).offset().top / 8.5);
    if(scroll > t) {
      
    if ($(window).scrollTop()>=($(".section5 li").eq(0).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(1).offset().top - 200)) {
      $(".section5 li").eq(0).css({
        "transform":"rotateX("+ t / 200+"deg)" + "scale("+ 4000 / t+")",
        'top': t / 4500 + 'px',
        'background':'rgba(' + alpha1 + ',' + alpha1 + ',' + alpha1 + ')'
      })
    }
    else if($(window).scrollTop()>=($(".section5 li").eq(1).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(2).offset().top - 260)) {
      $(".section5 li").eq(1).css({
        "transform":"rotateX("+ t / 300 +"deg)" + "scale("+ 4500 / t+")",
        'background':'rgba(' + alpha1 + ',' + alpha1 + ',' + alpha1 + ')'
      });
    }
    else if($(window).scrollTop()>=($(".section5 li").eq(2).offset().top - 260) && $(window).scrollTop()<($("footer").offset().top - 700)) {
      $(".section5 li").eq(2).css({
        "transform":"rotateX("+ t / 200 +"deg)" + "scale("+  4650 / t+")",
      });
    }
      
    } else {
      var alpha1 = Math.min(480 - 0.4 * $(".section5 li").eq(0).offset().top / 7);
    if ($(window).scrollTop()>=($(".section5 li").eq(0).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(1).offset().top - 200)) {
      $(".section5 li").eq(0).css({
        "transform":"rotateX("  + t / t +"deg)" + "scale("+ 4000 / t+")",
        'top': t / 4500 + 'px',
        'background':'rgba(' + alpha1b + ',' + alpha1b + ',' + alpha1b + ')'
      })
    }
    else if($(window).scrollTop()>=($(".section5 li").eq(1).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(2).offset().top - 260)) {
      $(".section5 li").eq(1).css({
        "transform":"rotateX("+ t / t +"deg)" + "scale("+ 4500 / t+")",
        'background':'rgba(' + alpha1c + ',' + alpha1c + ',' + alpha1c + ')'
      });
    }
    else if($(window).scrollTop()>=($(".section5 li").eq(2).offset().top - 260) && $(window).scrollTop()<($("footer").offset().top - 700)) {
      $(".section5 li").eq(2).css({
        "transform":"rotateX("+ t / t+"deg)" + "scale("+  4650 / t+")",
      });
    }
    }
    t = scroll;
  })
}
else if( window.innerWidth>1200 && window.innerWidth<1366) {
  var t = $(window).scrollTop();
  $(window).scroll(function () {
    // 可是窗口滚动条发生滚动的时候 垂直方向滚动到多少距离的时候 让头部导航条设置上一个固定定位的样式
    var scroll = $(window).scrollTop();
    var alpha1 = Math.min(480 - 0.4 * $(".section5 li").eq(0).offset().top / 7);
    var alpha1b = Math.min(460 - 0.3 * $(".section5 li").eq(0).offset().top / 7.5);
    var alpha1c = Math.min(460 - 0.3 * $(".section5 li").eq(0).offset().top / 8);
    var alpha1d = Math.min(460 - 0.3 * $(".section5 li").eq(0).offset().top / 8.5);
    if(scroll > t) {
      
    if ($(window).scrollTop()>=($(".section5 li").eq(0).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(1).offset().top - 200)) {
      $(".section5 li").eq(0).css({
        "transform":"rotateX("+ t / 200+"deg)" + "scale("+ 3500 / t+")",
        'top': t / 4500 + 'px',
        'background':'rgba(' + alpha1 + ',' + alpha1 + ',' + alpha1 + ')'
      })
    }
    else if($(window).scrollTop()>=($(".section5 li").eq(1).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(2).offset().top - 260)) {
      $(".section5 li").eq(1).css({
        "transform":"rotateX("+ t / 300 +"deg)" + "scale("+ 4000 / t+")",
        'background':'rgba(' + alpha1 + ',' + alpha1 + ',' + alpha1 + ')'
      });
    }
    else if($(window).scrollTop()>=($(".section5 li").eq(2).offset().top - 260) && $(window).scrollTop()<($("footer").offset().top - 700)) {
      $(".section5 li").eq(2).css({
        "transform":"rotateX("+ t / 200 +"deg)" + "scale("+  4500 / t+")",
      });
    }
      
    } else {
      var alpha1 = Math.min(480 - 0.4 * $(".section5 li").eq(0).offset().top / 7);
    if ($(window).scrollTop()>=($(".section5 li").eq(0).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(1).offset().top - 200)) {
      $(".section5 li").eq(0).css({
        "transform":"rotateX("  + t / t +"deg)" + "scale("+ 3500 / t+")",
        'top': t / 4500 + 'px',
        'background':'rgba(' + alpha1b + ',' + alpha1b + ',' + alpha1b + ')'
      })
    }
    else if($(window).scrollTop()>=($(".section5 li").eq(1).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(2).offset().top - 260)) {
      $(".section5 li").eq(1).css({
        "transform":"rotateX("+ t / t +"deg)" + "scale("+ 4000 / t+")",
        'background':'rgba(' + alpha1c + ',' + alpha1c + ',' + alpha1c + ')'
      });
    }
    else if($(window).scrollTop()>=($(".section5 li").eq(2).offset().top - 260) && $(window).scrollTop()<($("footer").offset().top - 700)) {
      $(".section5 li").eq(2).css({
        "transform":"rotateX("+ t / t+"deg)" + "scale("+  4500 / t+")",
      });
    }
    }
    t = scroll;
  })
}
else if( window.innerWidth>1023 && window.innerWidth<1200) {
  var t = $(window).scrollTop();
  $(window).scroll(function () {
    // 可是窗口滚动条发生滚动的时候 垂直方向滚动到多少距离的时候 让头部导航条设置上一个固定定位的样式
    var scroll = $(window).scrollTop();
    var alpha1 = Math.min(480 - 0.4 * $(".section5 li").eq(0).offset().top / 7);
    var alpha1b = Math.min(460 - 0.3 * $(".section5 li").eq(0).offset().top / 7.5);
    var alpha1c = Math.min(460 - 0.3 * $(".section5 li").eq(0).offset().top / 8);
    var alpha1d = Math.min(460 - 0.3 * $(".section5 li").eq(0).offset().top / 8.5);
    if(scroll > t) {
      
    if ($(window).scrollTop()>=($(".section5 li").eq(0).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(1).offset().top - 200)) {
      $(".section5 li").eq(0).css({
        "transform":"rotateX("+ t / 200+"deg)" + "scale("+ 3300 / t+")",
        'top': t / 4500 + 'px',
        'background':'rgba(' + alpha1 + ',' + alpha1 + ',' + alpha1 + ')'
      })
    }
    else if($(window).scrollTop()>=($(".section5 li").eq(1).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(2).offset().top - 260)) {
      $(".section5 li").eq(1).css({
        "transform":"rotateX("+ t / 300 +"deg)" + "scale("+ 3800 / t+")",
        'background':'rgba(' + alpha1 + ',' + alpha1 + ',' + alpha1 + ')'
      });
    }
    else if($(window).scrollTop()>=($(".section5 li").eq(2).offset().top - 260) && $(window).scrollTop()<($("footer").offset().top - 700)) {
      $(".section5 li").eq(2).css({
        "transform":"rotateX("+ t / 200 +"deg)" + "scale("+  4300 / t+")",
      });
    }
      
    } else {
      var alpha1 = Math.min(480 - 0.4 * $(".section5 li").eq(0).offset().top / 7);
    if ($(window).scrollTop()>=($(".section5 li").eq(0).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(1).offset().top - 200)) {
      $(".section5 li").eq(0).css({
        "transform":"rotateX("  + t / t +"deg)" + "scale("+ 3300 / t+")",
        'top': t / 4500 + 'px',
        'background':'rgba(' + alpha1b + ',' + alpha1b + ',' + alpha1b + ')'
      })
    }
    else if($(window).scrollTop()>=($(".section5 li").eq(1).offset().top - 120) && $(window).scrollTop()<($(".section5 li").eq(2).offset().top - 260)) {
      $(".section5 li").eq(1).css({
        "transform":"rotateX("+ t / t +"deg)" + "scale("+ 3800 / t+")",
        'background':'rgba(' + alpha1c + ',' + alpha1c + ',' + alpha1c + ')'
      });
    }
    else if($(window).scrollTop()>=($(".section5 li").eq(2).offset().top - 260) && $(window).scrollTop()<($("footer").offset().top - 700)) {
      $(".section5 li").eq(2).css({
        "transform":"rotateX("+ t / t+"deg)" + "scale("+  4300 / t+")",
      });
    }
    }
    t = scroll;
  })
}
